<template>
  <div>
    <header v-if="!loading" id="header" class="fixed-top d-flex align-items-center">
      <div
        class="container d-flex align-items-center justify-content-center"
        v-if="!this.$store.userTokenState"
      >
        <div class="logo mobile-inactive">
          <img :src="this.$store.state.logo" alt />
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center" >
          <nav id="navbar" :class="'navbar' + (activeMobile ? ' navbar-mobile' : '')">
            <i
              :class="
              'bi bi-list mobile-nav-toggle ' + (activeMobile ? ' bi-x' : '')
            "
              @click="activeMobile = !activeMobile"
            ></i>
            <div class="mobile-active" v-if="this.$localStorage.get(this.$store.storeName) != null">
              <!--notification-->
              <div class="dropdown">
                <a id="dLabel" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div
                    v-if="this.notifications != null"
                    style="
                    color: #fff;
                    font-size: 0.5rem;
                    margin-top: -55%;
                    padding: 0 5px;
                    background-color: #ed472b;
                    width: 100%;
                    border-radius: 25px;
                  "
                  >{{ notifications.length }}</div>
                  <i class="bi bi-bell"></i>
                </a>
                <ul
                  v-if="this.notifications != null"
                  class="dropdown-menu notifications"
                  role="menu"
                  aria-labelledby="dLabel"
                  style="right: 0; min-width: 240px"
                >
                  <div class="d-flex flex-row justify-content-between align-items-center">
                    <div class="notification-heading">
                      <h4 class="menu-title mt-2">{{ $t("Notifications") }}</h4>
                    </div>
                    <button v-if="this.notifications != null" class="delete" @click="show = true">
                      <h6 style="color: #ed472b">
                        {{ $t("deleteAll") }}
                        <span style="color: #ed472b">
                          <i class="bi bi-trash"></i>
                        </span>
                      </h6>
                    </button>
                  </div>
                  <li class="divider"></li>
                  <div class="notifications-wrapper" v-if="!loadingNote">
                    <a class="content" href="#" v-for="(item, index) in notifications" :key="index">
                      <div class="notification-item">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <h4 class="item-title">{{ item.title }}</h4>
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            @click="deleteNote(item.id, index)"
                          >
                            <p aria-hidden="true">&times;</p>
                          </button>
                        </div>

                        <p class="item-info">{{ item.body }}</p>
                        <span class="item-info">{{ item.date }}</span>
                      </div>
                    </a>
                  </div>
                  <li class="divider"></li>
                  <div class="notification-footer pb-2">
                    <router-link
                      :to="{
                      name: 'notifications',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("Viewall") }}</router-link>
                  </div>
                </ul>
              </div>
              <!--End notification-->
            </div>
            <ul>
              <li>
                <div class="mobile-active">
                  <div
                    class="logo d-flex flex-row justify-content-center align-items-center"
                    style="height: 70px; margin-bottom: 20px"
                  >
                    <img :src="this.$store.state.logo" alt />
                  </div>
                </div>
              </li>
              <li class="mobile-active">
                <i class="bi bi-search" @click="searchRoute"></i>
                <input
                  id="search"
                  type="search"
                  autocomplete="false"
                  onblur="this.setAttribute('readonly', 'readonly') , word=''"
                  onfocus="this.removeAttribute('readonly');"
                  readonly
                  class="form-control rounded"
                  :placeholder="$t('search')"
                  :aria-label="$t('search')"
                  aria-describedby="search-addon"
                  style="
                  min-width: 200px;
                  border-radius: 25px !important;
                  border: 2px solid #f7f8fb;
                "
                  v-model="word"
                  @keyup.enter="searchRoute"
                />
              </li>
              <div
                class="line mobile-inactive"
                style="
                background-color: #f7f8fb;
                width: 2px;
                height: 40px;
                margin-right: 20px;
                margin-left: 20px;
              "
              ></div>
              <li>
                <router-link
                  :to="{
                  name: 'home1',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("home") }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{
                  name: 'sections',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("sections") }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{
                  name: 'about',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("about") }}</router-link>
              </li>
            

              <li>
                <router-link
                  :to="{
                  name: 'contactUs',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("contactUs") }}</router-link>
              </li>

              <li
                class="mobile-inactive"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <div class="filterDropdown dropdown">
                  <router-link
                    :to="{
                    name: 'profile',
                    params: { storeName: this.$store.storeName },
                  }"
                  >{{ $t("profile") }}</router-link>
                  <ul>
                    <li>
                      <router-link
                        :to="{
                        name: 'myOrders',
                        params: { storeName: this.$store.storeName },
                      }"
                      >{{ $t("myOrders") }}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{
                        name: 'myAddresses',
                        params: { storeName: this.$store.storeName },
                      }"
                      >{{ $t("myAddresses") }}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{
                        name: 'favorites',
                        params: { storeName: this.$store.storeName },
                      }"
                      >{{ $t("favorites") }}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{
                        name: 'profile',
                        params: { storeName: this.$store.storeName },
                      }"
                      >{{ $t("profile") }}</router-link>
                    </li>
                    <li >
                     
                      <router-link
                        :to="{
                        name: 'home1',
                        params: { storeName: this.$store.storeName },
                      }"
                      @click.native="show2 = true" >{{ $t("logOut") }}</router-link>
                    </li>
                  </ul>

                </div>
              </li>
              <li
                class="mobile-active"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'profile',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("profile") }}</router-link>
              </li>
              <li
                class="mobile-active"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'myOrders',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("myOrders") }}</router-link>
              </li>
              <li
                class="mobile-active"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'myAddresses',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("myAddresses") }}</router-link>
              </li>
              <li
                class="mobile-active"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'favorites',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("favorites") }}</router-link>
              </li>
              <li>
                <select 
                 v-model="country" @change="change_country" id="country" 
                  class="form-control select_country" 
                  >
                 <option   v-for="(item, index) in this.countries" 
                  :value="item.id">{{item.name}}</option>
                        </select>
              </li>
             
              <li class="mobile-inactive">
                <i class="bi bi-search" @click="searchRoute"></i>
                <input
                  id="search"
                  type="search"
                  autocomplete="false"
                  onblur="this.setAttribute('readonly', 'readonly') , word=''"
                  onfocus="this.removeAttribute('readonly');"
                  readonly
                  class="form-control rounded"
                  :placeholder="$t('search')"
                  :aria-label="$t('search')"
                  aria-describedby="search-addon"
                  style="
                  min-width: 200px;
                  border-radius: 25px !important;
                  border: 2px solid #f7f8fb;
                "
                  v-model="word"
                  @keyup.enter="searchRoute"
                />
              </li>
             
              <li>
                <router-link
                  :to="{
                  name: 'shoppingCart',
                  params: { storeName: this.$store.storeName },
                }"
                  class="nav-link scrollto mobile-active"
                 
                >{{ $t("shoppingCart") }}</router-link>
              </li>
              <li>
                <router-link
                  class="nav-link scrollto mobile-inactive"
                  :to="{
                  name: 'shoppingCart',
                  params: { storeName: this.$store.storeName },
                }"
                >
                  <div
                    v-if="this.$store.state.cartCounter != 0"
                    style="
                    color: #fff;
                    font-size: 0.5rem;
                    padding: 0 3px;
                    background-color: #ed472b;
                    width: 100%;
                    border-radius: 25px;
                    top: -12px;
                    position: relative;
                    display: inline;
                  "
                  >{{ this.$store.state.cartCounter }}</div>
                  <i class="bi bi-cart3 mr-5"></i>
                </router-link>
              </li>
              <li
                class="mobile-inactive"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <div class="dropdown">
                  <a id="dLabel" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div
                      v-if="this.notifications != null"
                      style="
                      color: #fff;
                      font-size: 0.5rem;
                      margin-top: -55%;
                      padding: 0 5px;
                      background-color: #ed472b;
                      width: 100%;
                      border-radius: 25px;
                    "
                    >{{ notifications.length }}</div>
                    <i class="bi bi-bell"></i>
                  </a>

                  <ul
                    v-if="this.notifications != null"
                    class="dropdown-menu notifications"
                    role="menu"
                    aria-labelledby="dLabel"
                  >
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div class="notification-heading">
                        <h4 class="menu-title mt-2">{{ $t("Notifications") }}</h4>
                      </div>
                      <button v-if="this.notifications != null" class="delete" @click="show = true">
                        <h6 style="color: #ed472b">
                          {{ $t("deleteAll") }}
                          <span>
                            <i class="bi bi-trash"></i>
                          </span>
                        </h6>
                      </button>
                    </div>
                    <li class="divider"></li>
                    <div class="notifications-wrapper" v-if="!loadingNote">
                      <a
                        class="content"
                        href="#"
                        v-for="(item, index) in notifications"
                        :key="index"
                      >
                        <div class="notification-item">
                          <div class="d-flex flex-row justify-content-between align-items-center">
                            <h4 class="item-title">{{ item.title }}</h4>
                            <!--<p
                            style="color:#ed472b; cursor:pointer"
                            @click="deleteNote(item.id,index)"
                          >
                            <i class="bi bi-trash"></i>
                            </p>-->
                            <button
                              type="button"
                              class="close"
                              aria-label="Close"
                              @click="deleteNote(item.id, index)"
                            >
                              <p aria-hidden="true">&times;</p>
                            </button>
                          </div>
                          <p class="item-info">{{ item.body }}</p>
                          <span class="item-info">{{ item.date }}</span>
                        </div>
                      </a>
                    </div>
                    <li class="divider"></li>
                    <div class="notification-footer pb-2">
                      <router-link
                        :to="{
                        name: 'notifications',
                        params: { storeName: this.$store.storeName },
                      }"
                      >{{ $t("Viewall") }}</router-link>
                    </div>
                  </ul>
                </div>
              </li>
              <li v-if="this.$store.state.apperance_lang=='all'" id="lang">
                <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
                  <a>English</a>
                </button>
                <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
                  <a>عربي</a>
                </button>
              </li>
              <li v-if="this.$localStorage.get(this.$store.storeName) == null">
                <router-link
                  :to="{
                  name: 'login',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("logIn") }}</router-link>
              </li>
              <li
                class="mobile-active"
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'home1',
                  params: { storeName: this.$store.storeName },
                }"
                @click.native="show2 = true"
                >{{ $t("logOut") }}</router-link>
              </li>
              <!--<li>
            <select
              class="select"
              title="اللغات"
              v-model="$i18n.locale"
              @change="onChange($event)"
            >
              <option value="ar">{{ $t("Arabic") }}</option>
              <option value="en">{{ $t("English") }}</option>
              </select>-->
            </ul>
            <!-- .navbar -->
          </nav>
          <div   class="mobile-active" style=" margin: 0 10px;">
            <router-link
              class="nav-link scrollto"
              :to="{
                name: 'shoppingCart',
                params: { storeName: this.$store.storeName },
              }"
            >
              <div
                v-if="this.$store.state.cartCounter != 0"
                style="
                  color: #fff;
                  font-size: 0.5rem;
                  padding: 0 3px;
                  background-color: #ed472b;
                  width: 100%;
                  border-radius: 25px;
                  top: -12px;
                  position: relative;
                  display: inline;
                "
              >
                {{ this.$store.state.cartCounter }}
              </div>
              <i class="bi bi-cart3 mr-5"></i>
            </router-link>
          </div>
        </div>
        <div class="logo mobile-active">
          <img :src="this.$store.state.logo" alt />
        </div>
      </div>
    </header>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteAllNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteOneNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show1 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div v-if="show2" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show2 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Header",
  props: {
    // name: String,
    // image: String
    storeName: String
  },
  data: function() {
    return {
      loading: true,
      loadingNote: false,
      activeMobile: false,
      word: "",
      show: false,
      show1: false,
      show2: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      notifications: [],
      countries:[],
      country:6
    };
  },
  computed: {
    myState: function() {
      return this.$store.state.notificationUpdate; // return the state value in `my_state`
    }
  },
  watch: {
    myState: function(newVal, oldVal) {
      console.log(this.$store.notificationUpdate + " hiiiiiiiiiiii ");
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              console.log("heeeeeeeeeeeeeeeeeeee " + this.notifications);
            }
          })
          .then((this.loadingNote = false));
      }
    }
  },
  created() {

//////////////////////////////////////
 //////////////////////////
 console.log('///////////////////////////////////////////////////////////////////////////////////')
this.axios
        .get(
         this.$store.state.base_api_url + this.$store.storeName+"/market_countries",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.countries = response.data.data;
          console.log('gggg',this.countries)
       
        })
        .then(() => (this.loading = false));
///////////////////////////////

    ///////////////////////////////////////////////////////////
    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/new_note",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            //console.log(this.notifications);
          }
        })
        .then((this.loadingNote = false));
    }
    let AllCookies = this.$cookies.keys();
    let counter = 0;
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        counter++;
        //console.log(this.items);
      }
    }
    this.$store.state.cartCounter = counter;
    console.log(counter);
    this.loading=false;
  },
  methods: {
    change_country(){
     //////////////////////////
  console.log('///////////////////////////////////////////////////////////////////////////////////')
console.log(this.country)
  this.axios
        .get(
         this.$store.state.base_api_url + this.$store.storeName+"/currencyv1/"+this.country,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
           this.$store.state.rate=response.data.data.rate   
           this.$store.state.unit=response.data.data.unit_en    
           this.$store.state.unitAR=response.data.data.unit_ar
           this.$store.state.country_code = response.data.data.country_code;
           this.$store.state.country_flag = response.data.data.country_flag;
            this.$store.state.country_length = response.data.data.country_length; 
            if(this.$i18n.locale == 'ar')
  {
      this.$store.state.UnitPrice=this.$store.state.unitAR;
  }
  else
  {
      this.$store.state.UnitPrice=this.$store.state.unit;
  }     
  
        })
        .then(() => (this.loading = false));

       
///////////////////////////////
  },
    onChange(lang) {
      this.$store.state.langchange = true;
      this.$store.state.langchange1 = true; //for footer
      this.$i18n.locale = lang;

      console.log('///////////////////////////////////////////////////////////////////////////////////')
this.axios
        .get(
         "https://etrolley.net/api/eabaya/market_countries",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.countries = response.data.data;
          if(this.$i18n.locale == 'ar')
  {
      this.$store.state.UnitPrice=this.$store.state.unitAR;
  }
  else
  {
      this.$store.state.UnitPrice=this.$store.state.unit;
  }    
       
        })
        .then(() => (this.loading = false));
      if (lang == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        let h = document.querySelector('body');
        h.setAttribute('dir', 'rtl')

        document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
        h.setAttribute('dir', 'ltr')

        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              //console.log(this.notifications);
            }
          })
          .then((this.loadingNote = false));
      }
    },
    logOut() {
      this.loading = true;
      this.axios.get(
        this.$store.state.base_api_url + this.$store.storeName + "/logout",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      );
      //console.group("hiiiiii")
      console.log(
        "logout:" +
          //this.$localStorage.id() +
          this.$localStorage.get(this.$store.storeName)
      );
      //this.$localStorage.remove("userToken");
      this.show2=false;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        console.log('hhhhhhhhhhhhhhh111');
        if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
          this.$cookies.remove(console.log(AllCookies[i]));
        
        }
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

      }
      console.log(AllCookies);
      /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
      this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
      }*/
      this.$localStorage.remove(this.$store.storeName);
      window.location.reload();
      this.loading = false;
    },
    searchRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/Search")) {
        console.log(this.word + "hiiiiiii");
        this.$router.push({ name: "search", params: { word: this.word } });
      } else {
        this.$router.push({
          name: "search",
          params: { storeName: this.$store.storeName, word: this.word }
        });
      }
    },
    deleteAll() {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loadingNote = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_note/" +
            this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);

            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
          this.show2 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    }
    /* onClickMobile: function () {
      $("#navbar").addClass(" navbar-mobile");
      $(".mobile-nav-toggle").addClass("bi-list bi-x");
    },*/
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar .notification-item h4 {
  color: #000;
  text-align: start;
}

.dark_mode .navbar .notification-item h4 {
  color: var(--primary-color);
  text-align: start;
}
.navbar .notification-item p {
  font-size: 15px;
  margin: 0;
  max-width: 100%;
  line-break: anywhere;
  text-align: start;
  color: var(--secondary-color);
}

.dark_mode .navbar .notification-item p {
 
  color: white;
}
.navbar .notification-item span {
  color: var(--primary-color);
  font-size: 10px;
  text-align: start;
}

[dir="rtl"] .bi-search {
  margin-right: 72%;
  margin-top: 6px;
  position: absolute;
}
[dir="ltr"] .bi-search {
  margin-left: 72%;
  margin-top: 6px;
  position: absolute;
}

.filterDropdown ul,
li {
  list-style: none;
}
.filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
  min-height: 190px;
}
.dark_mode .filterDropdown ul
{
  background: #000;
}
/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 14px;
}
.dark_mode .filterDropdown ul a {
  color: var(--primary-color);
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
button {
  background-color: transparent;
  border: 0;
  /*border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px;*/
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.dark_mode .modal-container
{
  background-color: #000;
  box-shadow: 0 2px 8px rgba(177, 174, 174, 0.33);

}
.modal-header {
  margin-top: 0;
  color: var(--secondary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}
.dark_mode .modal-header {
  margin-top: 0;
  color: var(--primary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}


.modal-body {
  margin: 20px 0;
  font-family: "Araboto-Medium";
}

.modal-default-button {
  float: right;
  font-family: "Araboto-Medium";
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.dark_mode .modal-default-button {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.modal-default-button:hover {
  background-color: white;
}

.dark_mode .modal-default-button:hover {
  background-color: #000000;
}
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 1200px) and (min-width: 992px) {
  .navbar li {
    padding: 5px 7px 7px 7px;
  }
  .navbar a {
    padding: 5px 5px;
  }
  
  /* #header .logo img {
    max-height: 30px;
  }*/
}
.select_country
{
  width: 70px !important;
  font-size: 12px !important;
  font-weight: bold;
  color:#565656;
  padding:0px !important
}
.navbar li {
        padding: 5px 10px 7px 10px !important;
    }
@media (max-width: 800px) and (min-width: 300px) {
  .select_country
{
  width: max-content !important;
  font-size: 12px !important;
  font-weight: bold;
  color:#565656;
  padding:0px !important

}

}
@media (min-width: 1400px) {
    .navbar li {
        padding: 5px 10px 7px 25px !important;
    }
}
</style>
