<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" style="padding-bottom:0">
      <div class="container">
        <!--style="max-width: 1180px">-->
        <div class="row mt-4">
          <div dir="ltr" class="col-lg-12" v-if="!loadingSlider">
            <div class="heroCarousel">
              <carousel
              v-if="sliders.length !=0"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :autoplayTimeout=5000
                :autoplayDirection="forward"
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx">
                  <img v-if="item.value!=''" :src="item.image" @click="sliderLink(item.value)" />
                  <img v-else :src="item.image" />
                </slide>
              </carousel>
              <div v-else > <img src="../../public/assets/image/slide1.jpg" /></div>
            </div>
           
          </div>
         
        </div>
      </div>
    </section>
    <!-- End hero -->

    
    <!-- ======= Second Section ======= -->
    <section id="Second">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("sections") }}</h2>
          <router-link
            :to="{
              name: 'sections',
              params: { storeName: this.$store.storeName },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <slider v-if="!loadingSections" :items="sections"></slider>
      </div>
    </section>
    <!-- End Second -->
    <!-- ======= fiveth Section ======= -->
   
    <!-- End Fourth -->

    

    
    </div>
    <!-- End Third -->

    <!-- ======= Fourth Section ======= -->

    

     <!-- ======= Fifth Section ======= -->
   
    <!-- End Fifth -->
  </div>
</template>
<script>
import VueHorizontalList from "vue-horizontal-list";
import { Carousel, Slide } from "vue-carousel";
import { off } from "process";
import Slider from "../components/slider.vue";


export default {
  name: "CarouselPage",
  components: {
   
    VueHorizontalList,
    Carousel,
    Slide,
    Slider,
  },
  data() {
    return {
      DBitems: null,
      sliders: null,
      sections: null,
      offers: null,
      brands: null,
      products: null,
      latest_products: null,
      feutred_products:null,
      loading: false,
      loadingSlider: false,
      loadingSections: false,
      loadingOffers: false,
      loadingBrands:false,
      loadingProducts: false,
      loadingProducts_latest: false,
      loadingProducts_feutred: false,

      scrollAmount: 0,
      scrollAmount1: 0,

      images: [
        {
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp",
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(6).webp",
        },
      ],
      
    };
  },
  created() {
 
    //////////////////////sliders//////////////////////
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    this.loadingSlider = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/sliders")
      .then((response) => (this.sliders = response.data.data.sliders))
      .then(() => (this.loadingSlider = false));

    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
      })
      .then(() => (this.loadingSections = false));
    //////////////////////offers//////////////////////
   

      //////////////////////Brands//////////////////////
 
    //////////////////////products//////////////////////
   
    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////




 //////////////////////products//////////////////////

    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////




//////////////////////products//////////////////////

    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////







    //console.log("from home : " + this.$localStorage.id());
    /*}else{
      this.$router.push(
        { name: "home"+this.$store.state.theme_id }
      )
    }*/
  },
  methods: {
    theFormat(number) {
            return number.toFixed(0);
        },
    updatedata() {

      //////////////////////sliders//////////////////////
      this.loadingSlider = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sliders"
        )
        .then((response) => (this.sliders = response.data.data.sliders))
        .then(() => (this.loadingSlider = false));

      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sections",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sections = response.data.data.sections;
          /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loadingSections = false));
      //////////////////////offers//////////////////////
    
        //////////////////////Brands//////////////////////
 
      //////////////////////products//////////////////////
  
        ////////////////////////////

        

 //////////////////////products//////////////////////

    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////

//////////////////////products//////////////////////

    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////


      this.$store.state.langchange = false;
    },
    sliderLink(slidLink){
      window.location.href= slidLink
    }
    
  },
};
</script>
<style scoped>
.menu-wrapper {
  position: relative;
  /*max-width: 310px;height: 100px;*/
  margin: 1em auto;
  border: 0;
  background: transparent;
  overflow-x: hidden;
  overflow-y: hidden;
}

.menu-wrapper .menu {
  /*height: 120px;*/
  /*box-sizing: border-box;*/
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}

.menu-wrapper .item {
  display: inline-block;
  /*width: 100%;*/
  height: 100%;
  /* outline: 1px dotted gray;*/
  padding: 1em;
  box-sizing: border-box;
}

.menu-wrapper .paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  background-color: transparent;
  border: 0;
}
.menu-wrapper .left-paddle {
  left: 0;
}
.menu-wrapper .right-paddle {
  right: 0;
  transform: rotate(180deg);
}
.menu-wrapper .hidden {
  display: none;
}

.menu-wrapper .print {
  margin: auto;
  max-width: 500px;
}

.menu-wrapper span {
  display: inline-block;
  width: 100px;
}
.paddle img {
  box-shadow: 0 0 7px 0 rgb(134, 134, 134);
  border-radius: 50px;
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  margin-top: 50px;
  background-image: url("../../public/assets/image/background.png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 80%;
  /*margin-right: 15px !important;
    margin-left: 15px !important;
    overflow: hidden;
    position: relative;
    background: transparent;
    padding: 0;*/
}

#hero img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

#hero .heroCarousel {
  margin-top: 25px;
 /* height: 390px;
  width: 100%;
  max-width: 500px;*/
  aspect-ratio: 1200 / 400;
  border-radius: 15px;
  padding: 0;
}

#hero h2 {
  font-family: "Araboto-Black";
  font-size: 25px;
  text-align: center;
  color: var(--primary-color);
}

#hero h3 {
  font-family: "Araboto-Black";
  font-size: 18px;
  margin-top: 20px;
  color: #000;
  text-align: center;
}

#hero p {
  font-family: "Araboto-Medium";
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: var(--secondary-color);
}
#hero .info {
  margin-top: 0 !important;
}
#hero .info p,
#hero .info .number {
  color: #000;
  font-family: "Araboto-Medium";
  font-size: 25px;
}
#hero .info span {
  color: #747474;
  font-family: "Araboto-Medium";
  font-size: 16px;
}

#hero img {
  object-fit: cover;
}

#hero .info .bi {
  font-size: 60px;
  margin: 0 20px;
  color: var(--primary-color);
}

.section-title {
        padding-bottom: 10px;
    }
/*@media (min-width: 2000px) {
  #hero p {
    font-size: 25px !important;
    width: 70% !important;
  }
  .col2 {
    flex: 0 0 auto ;
    width: 16.66666667% !important;
  }
}

@media (min-width: 1400px) {

  #hero {
    margin-top: 120px;
  }

  #hero .heroCarousel {
    margin-top: 25px;
    height: 490px;
    width: 100%;
    border-radius: 25px;
    padding: 0;
  }

  #hero h2 {
    font-size: 60px;
  }

  #hero h3 {
    font-size: 40px;
  }

  #hero p {
    font-size: 20px;
    width: 80%;
  }
  #hero .info p {
    font-size: 34px;
  }
  #hero .info span {
    font-size: 20px;
  }
}*/

/*@media (min-width: 1024px) {
  #hero p {
    width: 90%;
  }
}*/

@media (max-width: 992px) {
  #hero .carousel-container {
    margin-bottom: 15px;
  }
  #hero h2 {
    font-size: 22px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    line-height: 20px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  #hero  .info .bi{
    font-size: 40px;
    margin: 0 10px;
  }
  #hero .info p{
   /* line-height: 10px;*/
    margin-top: 10px;
  }
  #hero .information {
    justify-content: center !important;
  }
  #hero .button {
    font-size: 16px;
  }
  #hero {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 630px) {
  #hero h2 {
    font-size: 21px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
  #hero  .info .bi {
    font-size: 30px;
  }
  #hero .button {
    font-size: 14px;
  }
 /* #hero .heroCarousel {
    height: 290px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }*/
}

@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  #hero h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  #hero p {
    font-size: 12px !important;
    margin-bottom: 3px !important;
  }
  #hero .button {
    width: 180px;
    font-size: 11px;
  }
  .section-title p {
    font-size: 16px;
  }
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
 
  
}

@media (max-width: 400px) {
  #hero h2 {
    font-size: 15px;
  }
  #hero h3 {
    font-size: 10px;
    margin-bottom: 7px !important;
  }
  #hero p,
  #hero .info span {
    font-size: 8px !important;
  }

  #hero .button {
    font-size: 7px;
  }
  /*#hero .heroCarousel {
    height: 200px;
  }*/
}
</style>
